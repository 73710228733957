input[type=checkbox] {
    background-color: red;
}

.css-tlfecz-indicatorContainer {
    color: #FF5900 !important;
}

.BoxShadow {
    box-shadow: 0 3px 6px #ccc !important;
    border-radius: 5px;
    border: 1px solid white;
    font-size: 12px;
}

.BoxShadow:hover,
.BoxShadow:focus {
    box-shadow: 0 0 1px 1px #eee !important;
}

.footer {
    background-color: #FFEDE3;
    color: #FF5900;
    direction: ltr;
}

.datePicker {
    outline: none;
    border-radius: 0;
    border: 0;
    width: 125px;
    box-sizing: border-box;
}

.right-sidepopup {
    justify-content: space-between;
}

.left-sidepopup {
    justify-content: space-between;
}

.datePicker::placeholder {
    color: black;
}

.datePicker:hover::placeholder {
    color: white;
}

.footer {

    bottom: 0;
    z-index: 1;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    text-align: center;

}

.footerIcon {
    color: #FF5900;
    background-color: white;
    align-items: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
}

.footerIcon:hover {
    text-decoration: none;
    color: white;
    background-color: #FF5900;
}

.css-yk16xz-control {
    border: 0 !important;
}

.ImageStyle {
    min-width: 118px;
}

.fa-search {
    height: 80%;
    background-color: white;
    display: flex;
    align-items: center;
    color: #FF5900;
    margin-left: 15px;
    margin-right: -25px;
}

.autoPlace>div {
    border: none !important;
    box-shadow: none !important;
}

.text-dark-green {
    color: darkgreen !important;
    font-weight: bold;
}

.second-beow-text {
    border: 1px solid #000;
    padding: 4px;
    text-align: center;
    color: #FF5900 !important;
    font-weight: bold;
}

.text-dark-red {
    color: red !important;
    font-weight: bold;
}

.home-service-title {
    text-align: center;
    color: #ff5900;
    font-size: 28px;
    font-weight: bold;
}

.top-breadcrumb {
    width: 100%;
    border-radius: .25rem;
    display: flex;
}

.top-breadcrumb a {
    font-size: 14px;
    font-weight: bold;
}

.mobile-bg-color {
    background-color: skyblue;
    padding: 10px;
}

.country-code {
    line-height: 2.5em
}

.row-seperate {
    width: 100%;
    height: 10px;
    background-color: #e9ecef;
    margin: 4px;
}

.error-info {

    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.delivery-cost {
    text-align: center;
    color: #5f021f;
}

.share-btns {
    width: 100%;
    margin: auto;
    display: none;
}

.share-col-icon {
    display: inline-block;
    margin: 3px;
}

.toast.show {
    background-color: #fff;
}

.share-btn-head {
    margin-top: 20px;
}

.close-btn-request {
    width: 100%;
    text-align: center;
}

.request-msg-color {
    color: #FF5900 !important;
}

.request-instruction {
    color: darkblue;
    text-align: center;
    font-size: 20px;
}

.otp-entry-text {
    display: flex;
    line-height: 3em;
}

.success-box {
    color: green;
}

.pludge-sms {
    color: red;
    font-size: 12px
}

.loading-resend {
    color: blue;
    cursor: pointer;
}

.red-text-box {
    color: red;
}

.complaint-status-check {
    width: 100px;
}

.task-type-head {
    color: #ff5900;
    font-size: 14px;
}

.pro-sidebar .pro-menu a {
    color: #000 !important;
}

.selected {
    font-weight: bold;
    color: red !important;
    background-color: yellow;
    padding: 4px 8px;
}

.device-fix-status {
    color: green;
    padding: 5px;
    font-weight: bold
}

.font-small {
    font-size: 12px;
}

.request-id-span {
    color: violet !important;
}

.count-disp-wrapper {
    width: 100%;
    height: 30px;
    background-color: blanchedalmond;
    border: 1px solid #6c757d;
    justify-content: center;
}

.count-show {
    padding: 1px 10px;
    margin-top: 2px;
    color: #fff;
    border-radius: 21px;
    height: 23px;

    background-color: #FF5900;
}

.toast-btn-s {
    width: 40%;
    margin: 10px;
    display: inline-block;
}

.country-code-edit {
    line-height: 5em
}

.contact-en {
    flex-direction: row-reverse;
}

.contact-ar {
    flex-direction: row-reverse;
}

.map_text {
    color: #5f021f;
    font-size: 16px;
    text-align: center;
}

.map_box {
    /**border: 1px solid #d7d7d7;**/
    padding: 20px;
    justify-content: center;

    /* line-height: 3em; */

    /* height: 240px;*/
    border-radius: 10px;
}

.text-map-wrapper {
    margin-top: 70px;
}

.fixed-botton {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*   background: rgba(0, 0, 0, 0.5);*/
    /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Make sure the overlay appears above other elements */
}

@media screen and (max-width:767px) {
    .justify-content-center-sm {
        justify-content: space-around;
    }

    .share-btns {
        display: block;
    }

    .home-service-title {
        font-size: 20px;
    }
}